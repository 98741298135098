<template>
    <div>
        <section class="register-page">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h4 style="color: var(--theme-deafult)">Modifier vos coordonnées</h4>
                        <div class="theme-form">
                            <div class="form-row">
                                <div class="col-md-6">
                                    <label for="tel">Telephone *</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            id="tel"
                                            placeholder="Telephone"
                                            required
                                            v-model="form2.phone"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="email">Email *</label>
                                    <input type="email" class="form-control" id="email" placeholder="Email" required
                                           v-model="form2.email"/>
                                </div>
                            </div>
                            <br/>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <br/>
                                    <button class="btn btn-sm btn-solid" @click="checkCurrentParameter">Sauvegarder
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="register-page">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 ">
                        <h4 style="color: var(--theme-deafult)">Adresses</h4>
                        <div class="row">
                            <div class="col-md-4 col-sm-6 col-xs-12 border p-1 m-1" v-for="(adress, i) in user.addresses" :key="i" >
                                <div class="dashboard" style="cursor: pointer" v-ripple @click="editAdress(adress, i)">
                                    <div class="psmage-title">
                                        <h3>{{ adress.adress }}</h3>
                                        <div class="box">
                                            {{ adress.city.name }}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <h4 style="color: var(--theme-deafult)">Ajouter / Modifier addresses</h4>
                        <div class="theme-form">
                            <div class="form-row">
                                <div class="col-md-6">
                                    <label for="address-one">Addresse</label>
                                    <input
                                            type="text"
                                            class="form-control"
                                            id="address-one"
                                            placeholder="Addresse de résidence"
                                            required
                                            v-model="form3.adress"
                                    />
                                </div>
                                <div class="col-md-6">
                                    <label for="city-one">Ville *</label>
                                    <v-select id="city-one" :options="cities" class="style-chooser" label="name"
                                              v-model="form3.city"></v-select>
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-12">
                                    <br/>
                                    <button class="btn btn-sm btn-solid" @click="addEditAddress">
                                        {{ editAddr ? 'Modifier' : 'Ajouter' }}
                                    </button>
                                    <button class="btn btn-sm btn-solid ml-1" @click="removeAdress" v-if="editAddr">Effacer
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </section>
       <change-password/>

    </div>
</template>
<script>

  import axios from "axios";
  import { mapGetters } from "vuex";
  import ChangePassword from "../../../ascension/components/changePassword";

  export default {
    name: "setting",

    components: { ChangePassword },
    data() {
      return {
        editAddr: false,
        addrIndex: 0,


        form2: {
          email: null,
          phone: null
        },

        form3 : {
          id: null,
          adress: "",
          city: null
        }

      };
    },

    watch: {
      user: {
        immediate: true,
        handler: function(val) {
          this.fillForm2(val)
        }
      }
      /*customer: {
        immediate: true,
        handler: function (val) {
          if (val) {
            this.isGuest = false;
            this.form.customer = val;
          } else {
            this.form.customer = "";
            this.isGuest = true;
          }
        },
      }*/
    },

    methods: {
      editAdress(addr, idx){
        this.editAddr = !this.editAddr
        if(this.editAddr) {
          this.fillForm3(addr, idx)
        }else{
          this.clearForm3()
        }
      },

      addEditAddress: function(){
        let loader = this.$loading.show();
        this.form3["customer"] = this.user.id;
        axios.post("changeAdress", this.form3)
          .then(data => {
            loader.hide();
            if (data.data.success) {
              this.clearForm3();
              this.$swal("Parametre modifie", data.data.message, "success");
              this.$store.commit("auth/SET_USER_DATA", data.data.data);
            } else {
              this.$swal("Erreur",
                data.data.message,
                "error");
            }
          })
          .catch(err => {
            loader.hide();
            this.$swal("Erreur",
              err.message,
              "error");
          });
      },

      removeAdress: function(){
        let loader = this.$loading.show();
        this.form3["customer"] = this.user.id;
        axios.post("removeAdress", this.form3)
          .then(data => {
            loader.hide();
            if (data.data.success) {
              this.clearForm3();
              this.$swal("Parametre modifie", data.data.message, "success");
              this.$store.commit("auth/SET_USER_DATA", data.data.data);
            } else {
              this.$swal("Erreur",
                data.data.message,
                "error");
            }
          })
          .catch(err => {
            loader.hide();
            this.$swal("Erreur",
              err.message,
              "error");
          });
      },

      checkCurrentParameter: function() {
        let loader = this.$loading.show();
        this.form2["customer"] = this.user.id;
        axios.post("changeContactInfo", this.form2)
          .then(data => {
            loader.hide();
            if (data.data.success) {
              this.$swal("Parametre modifie", data.data.message, "success");
              console.log("page", this.$route.query.page);
              this.$store.commit("auth/SET_USER_DATA", data.data.data);
              if (this.$route.query.page != undefined && this.$route.query.page == "checkout") {
                window.location.href = "/#/checkout";
              }
            }
            else {
              this.$swal("Erreur", data.data.message, "error");
            }
          })
          .catch(err => {
            loader.hide();
            this.$swal("Erreur",
              err.message,
              "error");
          });
        this.form2["customer"] = "";
      },

      clearForm1() {
        this.form1.password = "";
        this.form1.newpassword = "";
        this.form1.repeatednewpassword = "";
      },

      clearForm3() {
        this.form3.id = null
        this.form3.adress = "";
        this.form3.city = "";

        this.addrIndex = 0
        this.editAddr = false
      },

      fillForm3(data, idx) {
        this.form3.id = data.id
        this.form3.adress = data.adress;
        this.form3.city = data.city;
        this.addrIndex = idx
      },

      clearForm2() {
        this.form2.phone = "";
        this.form2.email = "";
      },

      fillForm2(data) {
        this.form2.phone = data.tel;
        this.form2.email = data.user.email;
      },


      checkForm1: function(e) {
        this.errors = [];
        if (!this.fname) {
          this.errors.push("First name required.");
        }
        if (!this.lname) {
          this.errors.push("Last name required.");
        }
        if (!this.email) {
          this.errors.push("Email required.");
        } else if (!this.validEmail(this.email)) {
          this.errors.push("Valid email required.");
        }
        if (!this.phone) {
          this.errors.push("Phone Number required.");
        }
        if (!this.city) {
          this.errors.push("Ville required.");
        }
        if (!this.message) {
          this.errors.push("Message required.");
        }
        if (!this.errors.length) return true;
        e.preventDefault();
      },
      validEmail: function(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      }
    },

    computed: {
      ...mapGetters("auth", ["isLogged", "user"]),
      ...mapGetters({
        customer: "customer/customer",
        cities: "city/cities"
      })
    },

    mounted() {
      this.$store.dispatch("city/fetchCities");
    }
  };
</script>
<style scoped>
    /*.vs__search{
      display: none !important;
    }*/
</style>
